import React from "react"
import { ExhibitionContainer } from "./styles"
import Layout from "../share/layout";

const Primer = () => {
  return (
    <Layout
      title="Arquitectura UPC"
      metaTitle="Arquitecturas de la Imaginación 2023-2 [Facultad de Arquitectura UPC]"
      description="Exposición virtual de los mejores trabajos de los estudiantes de los Talleres de Diseño y del área de Expresión Gráfica de la Facultad de Arquitectura de la Universidad Peruana de Ciencias Aplicadas (UPC) del ciclo 2023-2."
      subtitile="Arquitecturas de la Imaginación 2023-2"
      metaDescripcin="Exposición virtual de los mejores trabajos de los estudiantes de los Talleres de Diseño y del área de Expresión Gráfica de la Facultad de Arquitectura de la Universidad Peruana de Ciencias Aplicadas (UPC) del ciclo 2023-2."
      imgurl="//images.ctfassets.net/bv5rnjawitjg/1aQ1UsfwhW95vX6CX3GezG/50c1cfe42bb4504cfea4cf0d9822ef8e/Arq-Imag-22.1_800x800.jpg?h=300"
      keywords="arquitectura, exhibición, Arquitecturas de la imaginación, UPC Cultural, taller de diseño arquitectónico, expresión gráfica, Facultad de arquitectura UPC"
    >
      <ExhibitionContainer>
        <div className="title-exh">
          <h2>ARQUITECTURAS DE LA IMAGINACIÓN UPC<br />[Categoría Carlos Williams_ Nivel Inicial- TI & TII]</h2>
          <a href='/galeria/arquitectura-upc-2023-2'><h4>VOLVER</h4></a>
        </div>
        <div className="container-iframe">
          <iframe
            allowfullscreen="true"
            width="100%"
            height="100%"
            frameBorder="0"
            scrolling="no"
            src='https://art.kunstmatrix.com/apps/artspaces/?external=true&uid=58146&exhibition=12063446'
            title='Arquitecturas de la Imaginación [Categoría Carlos Williams_ Nivel Inicial- TI & TII]'
          />
        </div>
      </ExhibitionContainer>
    </Layout>
  )
}

export default Primer
